import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Credentials } from '../../common/services/auth.service';
import { UsersResource } from '../resources/users.resource';
import { NotifyService } from '../../common/services/notify.service';
import { BaseForm } from '../../common/base/base-form.component';
import { AppState } from '../../store';
import { environment } from '../../../environments/environment';
import { SegmentService } from '../../common/services/segment.service';
import { first } from 'rxjs';

@Component({
  selector: 'forgot-password',
  template: `
    <div class="panel">
      <div class="panel-header">
        <div class="panel-logo">
          <img class="logo-img" width="80" height="80" src="assets/img/xplenty-logo.svg" alt="Prepforce" />
        </div>
        <h3 class="panel-title">{{ 'auth.forgot_password.form.title' | translate }}</h3>
      </div>
      <div class="panel-body">
        <p>{{ 'auth.forgot_password.form.sub-title' | translate }}</p>
        <xp-form-validation type="User">
          <form name="passwordResetForm" novalidate #form="ngForm">
            <fieldset>
              <xp-form-group>
                <label>{{ 'auth.forgot_password.form.labels.email' | translate }}</label>
                <input
                  class="form-control"
                  [(ngModel)]="credentials.email"
                  [placeholder]="'auth.forgot_password.form.placeholders.email' | translate"
                  name="email"
                  type="email"
                  maxlength="254"
                  required
                />
              </xp-form-group>
            </fieldset>

            <xp-submit-button
              (submitForm)="submit()"
              classNames="btn-lg btn-block btn-primary"
              [isFormValid]="form.valid"
              [isFormSubmitting]="isLoading"
              [updateText]="'auth.reset-password.actions.reset' | translate"
            ></xp-submit-button>
          </form>
        </xp-form-validation>
      </div>
      <div class="panel-footer text-center panel-links">
        <span>{{ 'auth.sign_up.form.labels.have_account' | translate }}</span>
        <a (click)="goToSignIn()">{{ 'auth.sign_up.form.actions.sign_in' | translate }}</a>
      </div>
    </div>
    <help-menu></help-menu>
  `,
})
export class ForgotPasswordComponent extends BaseForm implements BaseForm {
  @ViewChild('form') form: NgForm;
  formName = 'passwordResetForm';
  credentials: Credentials = {
    email: '',
  };
  isLoading = false;

  constructor(
    private usersResource: UsersResource,
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private router: Router,
    private segmentService: SegmentService,
  ) {
    super();
    // @ts-ignore
    window.email_required = true;

    this.segmentService.segmentLoaded.pipe(first()).subscribe(() => {
      this.segmentService.analytics.load(environment.SEGMENT_PUBLIC_KEY, { integrations: { HubSpot: false } });
    });
  }

  submit() {
    this.isLoading = true;
    this.usersResource.resetUserPassword(this.credentials).subscribe({
      next: () => {
        this.isLoading = false;
        this.credentials.email = '';
        this.notify.success(this.translate.instant('auth.forgot_password.form.success_message'), '', 20000);
      },
      error: ({ error }) => {
        this.isLoading = false;
        const { errors } = error;
        if (errors && errors[0] && errors[0].field === 'invitation_token') {
          this.notify.error(`Token ${errors[0].message}`);
        }
      },
    });
  }

  goToSignIn() {
    this.router.navigate(['/auth/login']);
  }
}
