export enum COMPONENT_TYPE {
  CLOUD_STORAGE_SOURCE_COMPONENT = 'cloud_storage_source_component',
  DATABASE_SOURCE_COMPONENT = 'database_source_component',
  AMAZON_REDSHIFT_SOURCE_COMPONENT = 'amazon_redshift_source_component',
  BIG_QUERY_SOURCE_COMPONENT = 'big_query_source_component',
  MONGO_SOURCE_COMPONENT = 'mongo_source_component',
  FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT = 'facebook_ads_insights_source_component',
  ADWORDS_SOURCE_COMPONENT = 'adwords_source_component',
  ANALYTICS_SOURCE_COMPONENT = 'analytics_source_component',
  ANALYTICS_GA4_SOURCE_COMPONENT = 'analytics_ga4_source_component',
  REST_API_SOURCE_COMPONENT = 'rest_api_source_component',
  REST_API_DESTINATION_COMPONENT = 'rest_api_destination_component',
  SALESFORCE_SOURCE_COMPONENT = 'salesforce_source_component',
  BING_ADS_SOURCE_COMPONENT = 'bing_ads_source_component',
  NET_SUITE_SOURCE_COMPONENT = 'net_suite_source_component',
  SPANNER_SOURCE_COMPONENT = 'spanner_source_component',
  SELECT_COMPONENT = 'select_component',
  SORT_COMPONENT = 'sort_component',
  RANK_COMPONENT = 'rank_component',
  LIMIT_COMPONENT = 'limit_component',
  WINDOW_COMPONENT = 'window_component',
  SAMPLE_COMPONENT = 'sample_component',
  JOIN_COMPONENT = 'join_component',
  CROSS_JOIN_COMPONENT = 'cross_join_component',
  CLONE_COMPONENT = 'clone_component',
  UNION_COMPONENT = 'union_component',
  FILTER_COMPONENT = 'filter_component',
  ASSERT_COMPONENT = 'assert_component',
  AGGREGATE_COMPONENT = 'aggregate_component',
  CUBE_COMPONENT = 'cube_component',
  DISTINCT_COMPONENT = 'distinct_component',
  AMAZON_REDSHIFT_DESTINATION_COMPONENT = 'amazon_redshift_destination_component',
  BIG_QUERY_DESTINATION_COMPONENT = 'big_query_destination_component',
  DATABASE_DESTINATION_COMPONENT = 'database_destination_component',
  MONGO_DESTINATION_COMPONENT = 'mongo_destination_component',
  CLOUD_STORAGE_DESTINATION_COMPONENT = 'cloud_storage_destination_component',
  SPANNER_DESTINATION_COMPONENT = 'spanner_destination_component',
  SNOWFLAKE_DESTINATION_COMPONENT = 'snowflake_destination_component',
  SALESFORCE_DESTINATION_COMPONENT = 'salesforce_destination_component',
  SALESFORCE_SOAP_DESTINATION_COMPONENT = 'salesforce_soap_destination_component',
  NETSUITE_SOAP_DESTINATION_COMPONENT = 'netsuite_soap_destination_component',
  NETSUITE_DESTINATION_COMPONENT = 'netsuite_soap_destination_component',
  GOOGLE_ADS_DESTINATION_COMPONENT = 'google_ads_destination_component',
  FACEBOOK_ADS_DESTINATION_COMPONENT = 'facebook_ads_destination_component',
  HUBSPOT_DESTINATION_COMPONENT = 'hubspot_destination_component',
  TIKTOK_ADS_DESTINATION_COMPONENT = 'tiktok_ads_destination_component',
  RUN_PACKAGE_COMPONENT = 'run_package_component',
  EXECUTE_SQL_COMPONENT = 'execute_sql_component',
  FILE_MOVER_COMPONENT = 'file_mover_component',
  STICKY_NOTE_COMPONENT = 'sticky_note_component',
  AI_TRANSFORMATIONS = 'ai_transformations',
}

export const SOURCE_COMPONENT_TYPES = [
  COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT,
  COMPONENT_TYPE.DATABASE_SOURCE_COMPONENT,
  COMPONENT_TYPE.AMAZON_REDSHIFT_SOURCE_COMPONENT,
  COMPONENT_TYPE.BIG_QUERY_SOURCE_COMPONENT,
  COMPONENT_TYPE.MONGO_SOURCE_COMPONENT,
  COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT,
  COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT,
  COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT,
  COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT,
  COMPONENT_TYPE.REST_API_SOURCE_COMPONENT,
  COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT,
  COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT,
  COMPONENT_TYPE.NET_SUITE_SOURCE_COMPONENT,
  COMPONENT_TYPE.SPANNER_SOURCE_COMPONENT,
];

export const DESTINATION_COMPONENT_TYPES = [
  COMPONENT_TYPE.AMAZON_REDSHIFT_DESTINATION_COMPONENT,
  COMPONENT_TYPE.BIG_QUERY_DESTINATION_COMPONENT,
  COMPONENT_TYPE.DATABASE_DESTINATION_COMPONENT,
  COMPONENT_TYPE.MONGO_DESTINATION_COMPONENT,
  COMPONENT_TYPE.CLOUD_STORAGE_DESTINATION_COMPONENT,
  COMPONENT_TYPE.SPANNER_DESTINATION_COMPONENT,
  COMPONENT_TYPE.SNOWFLAKE_DESTINATION_COMPONENT,
  COMPONENT_TYPE.SALESFORCE_DESTINATION_COMPONENT,
  COMPONENT_TYPE.SALESFORCE_SOAP_DESTINATION_COMPONENT,
  COMPONENT_TYPE.NETSUITE_SOAP_DESTINATION_COMPONENT,
  COMPONENT_TYPE.NETSUITE_DESTINATION_COMPONENT,
  COMPONENT_TYPE.GOOGLE_ADS_DESTINATION_COMPONENT,
  COMPONENT_TYPE.FACEBOOK_ADS_DESTINATION_COMPONENT,
  COMPONENT_TYPE.HUBSPOT_DESTINATION_COMPONENT,
  COMPONENT_TYPE.REST_API_DESTINATION_COMPONENT,
];

export const TRANSFORMATION_COMPONENT_TYPES = [
  COMPONENT_TYPE.SELECT_COMPONENT,
  COMPONENT_TYPE.SORT_COMPONENT,
  COMPONENT_TYPE.RANK_COMPONENT,
  COMPONENT_TYPE.LIMIT_COMPONENT,
  COMPONENT_TYPE.WINDOW_COMPONENT,
  COMPONENT_TYPE.SAMPLE_COMPONENT,
  COMPONENT_TYPE.JOIN_COMPONENT,
  COMPONENT_TYPE.CROSS_JOIN_COMPONENT,
  COMPONENT_TYPE.CLONE_COMPONENT,
  COMPONENT_TYPE.UNION_COMPONENT,
  COMPONENT_TYPE.FILTER_COMPONENT,
  COMPONENT_TYPE.ASSERT_COMPONENT,
  COMPONENT_TYPE.AGGREGATE_COMPONENT,
  COMPONENT_TYPE.CUBE_COMPONENT,
  COMPONENT_TYPE.DISTINCT_COMPONENT,
];

export function isSourceComponentType(componentType: COMPONENT_TYPE): boolean {
  return SOURCE_COMPONENT_TYPES.includes(componentType);
}

export function isDestinationComponentType(componentType: COMPONENT_TYPE): boolean {
  return DESTINATION_COMPONENT_TYPES.includes(componentType);
}
