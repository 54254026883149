import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AppState } from '../../store';
import { NotifyService } from '../../common/services/notify.service';
import { BaseForm, BaseFormInterface } from '../../common/base/base-form.component';
import { Account } from '../../account/account.models';
import { RegionsResource } from '../../connections/resources/regions.resource';
import { AccountResource } from '../../account/resources/account.resource';
import { updateAccountInfo } from '../../account/store/account.actions';
import { selectAccountId, selectAccountPermissions } from '../../account/store/account.selectors';
import { ValidationError } from '../../config/validation-error.model';

@Component({
  selector: 'account-settings-info',
  template: `
    <div class="settings-body">
      <xp-form-validation type="Account" [name]="formName">
        <form id="accountForm" name="accountForm" #form="ngForm" (ngSubmit)="saveAccount()">
          <div class="form-group">
            <xp-form-group>
              <label for="name">{{ 'account.settings.info.labels.name' | translate }}</label>
              <xp-input
                id="name"
                type="text"
                class="form-control"
                name="name"
                [(ngModel)]="account.name"
                [attr.readonly]="isNotAvailable | async"
              ></xp-input>
            </xp-form-group>
            <small>{{ 'account.settings.info.hints.name' | translate }}</small>
          </div>
          <div class="form-group">
            <xp-form-group class="form-group has-feedback">
              <label class="control-label"
                >{{ 'account.settings.info.labels.account_id' | translate }}
                <span *ngxPermissionsOnly="'updateProfile'"
                  >(<a (click)="toggleAccountIdEdit()"><span *ngIf="accountIdEdit">cancel&nbsp;</span>change</a>)</span
                ></label
              >
              <xp-input
                type="text"
                name="account_id"
                [attr.readonly]="!accountIdEdit ? 'true' : null"
                class="form-control"
                [(ngModel)]="account.account_id"
                id="account-id"
              ></xp-input>
            </xp-form-group>
            <small *ngIf="!accountIdEdit">{{ 'account.settings.info.hints.account_id' | translate }}</small>
            <small *ngIf="accountIdEdit">{{ 'account.settings.info.hints.account_id_change' | translate }}</small>
          </div>
          <div class="form-group">
            <xp-form-group [validationDisabled]="true">
              <label>{{ 'account.settings.info.labels.region' | translate }}</label>
              <xp-select
                class="form-control xp-select"
                id="region-picker"
                name="region-picker"
                [value]="account.region"
                [options]="regions"
                (valueChange)="onRegionChange($event)"
                [disabled]="isNotAvailable | async"
                emptyPlaceholder="No region found"
              >
              </xp-select>
            </xp-form-group>
            <small [innerHTML]="'account.settings.info.hints.region' | translate"></small>
          </div>
          <div class="form-group">
            <xp-form-group>
              <label
                >{{ 'account.settings.info.labels.location' | translate }}
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <xp-input
                type="text"
                name="location"
                class="form-control"
                [(ngModel)]="account.location"
                [attr.readonly]="isNotAvailable | async"
                maxlength="255"
              ></xp-input>
            </xp-form-group>
            <small>{{ 'account.settings.info.hints.location' | translate }}</small>
          </div>
          <div class="form-group">
            <xp-form-group>
              <label
                >{{ 'account.settings.info.labels.billing_email' | translate }}
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <xp-input
                type="email"
                id="billing_email"
                name="billing_email"
                class="form-control"
                [(ngModel)]="account.billing_email"
                [attr.readonly]="isNotAvailable | async"
              ></xp-input>
            </xp-form-group>
          </div>
          <div class="form-group">
            <xp-form-group>
              <label [innerHTML]="'account.settings.info.labels.gravatar_email' | translate">
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <xp-input
                type="email"
                name="gravatar_email"
                id="gravatar_email"
                class="form-control"
                [(ngModel)]="account.gravatar_email"
                [attr.readonly]="isNotAvailable | async"
              ></xp-input>
            </xp-form-group>
            <small [innerHTML]="'account.settings.info.hints.gravatar_email' | translate"></small>
          </div>
          <xp-submit-button
            classNames="btn-md btn-success"
            [updateText]="'account.settings.info.actions.submit' | translate"
            [isFormValid]="form.valid"
            [isFormSubmitting]="isSubmitting"
            *ngxPermissionsOnly="'updateProfile'"
          ></xp-submit-button>
        </form>
      </xp-form-validation>
      <br />
      <div class="public-key-container">
        <h2>{{ 'account.settings.info.labels.public_key' | translate }}</h2>
        <p [innerHTML]="'account.settings.info.hints.public_key' | translate"></p>
        <pre class="input"><code>{{ account.public_key }}</code></pre>
      </div>
      <br />
    </div>
  `,
})
export class AccountSettingsInfoComponent extends BaseForm implements OnInit, BaseFormInterface {
  account: Partial<Account> = {};
  default: Partial<Account> = {};
  @ViewChild('form') form: NgForm;
  isSubmitting = false;
  formName = 'userForm';
  successMessageText = 'account.settings.success_message';
  accountPermissions$ = this.store.select(selectAccountPermissions);
  accountId$ = this.store.pipe(selectAccountId);
  errors$ = new Subject<ValidationError[]>();

  valid = false;
  regions = [];
  accountIdEdit = false;

  constructor(
    private accountResource: AccountResource,
    private regionsResource: RegionsResource,
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();

    this.accountId$.pipe(mergeMap((accountId: string) => accountResource.get(accountId))).subscribe({
      next: (account) => {
        this.account = account;
        this.default = { ...account };
      },
      error: () => {
        this.notify.error('An error occurred while loading account data.');
      },
    });

    this.regionsResource
      .query('', {
        sort: 'name',
        direction: 'asc',
        limit: '100',
      } as any)
      .subscribe({
        next: (regions) => {
          this.regions = regions.map(({ id, name }) => ({
            text: name,
            value: id,
            unavailable: id !== 'virginia',
          }));
        },
        error: () => {
          this.notify.error('An error occurred while loading regions data.');
        },
      });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  get isNotAvailable() {
    return this.accountPermissions$.pipe(map((permissions) => (!permissions.includes('updateProfile') ? true : null)));
  }

  onRegionChange(region: string) {
    this.account.region = region;
  }

  toggleAccountIdEdit() {
    this.accountIdEdit = !this.accountIdEdit;
    if (!this.accountIdEdit) {
      this.account.account_id = this.default.account_id;
    }
  }

  saveAccount() {
    this.isSubmitting = true;

    this.accountId$
      .pipe(mergeMap((accountId: string) => this.accountResource.update(accountId, this.account)))
      .subscribe({
        next: (account) => {
          this.account = { ...account };
          this.default = { ...this.account };
          this.accountIdEdit = false;
          this.store.dispatch(updateAccountInfo({ account }));
          this.isSubmitting = false;
        },
        error: (res: HttpErrorResponse) => {
          this.isSubmitting = false;

          if (res.error) {
            if (res.error.errors) {
              this.errors$.next(res.error.errors);
            }
          }
        },
      });
  }
}
